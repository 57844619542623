
import { Component } from "vue-property-decorator";
import CreateMixin from "@/mixins/Crud/CreateMixin";
import { Software } from "@/config/Modules";
import SoftwareForm from "@/views/BaseData/Software/SoftwareForm.vue";
import { mixins } from "vue-class-component";
import { SoftwareStoreMixin } from "@/mixins/Stores/BaseData/SoftwareStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import ICreateMixin from "@/mixins/interface/ICreateMixin";

@Component({
  components: { SoftwareForm },
  mixins: [CreateMixin, SoftwareStoreMixin],
})
export default class SoftwareCreate
  extends mixins(SoftwareStoreMixin, CreateMixin)
  implements ICreateMixin
{
  protected name = "SoftwareCreate";

  public get resource(): string {
    return Software.resource;
  }

  public get descriptionField(): string {
    return Software.description_field;
  }

  public createItemAction(options: ICrudOptions): any {
    return this.createSoftwareItemAction(options);
  }

  public redirectAfterCreateSuccess(responseData: any): void {
    this.$router.replace({
      name: "BaseDataSoftwareUpdate",
      params: { id: responseData.id },
    });
  }
}
